import Gallery7605 from '@assets/images/gallery/DUC_7605_big.jpeg';
import Gallery7605Thumb from '@assets/images/gallery/DUC_7605_thumb.jpeg';
import Gallery7752 from '@assets/images/gallery/DUC_7752_big.jpeg';
import Gallery7752Thumb from '@assets/images/gallery/DUC_7752_thumb.jpeg';
import Gallery7849 from '@assets/images/gallery/DUC_7849_big.jpeg';
import Gallery7849Thumb from '@assets/images/gallery/DUC_7849_thumb.jpeg';
import Gallery7906 from '@assets/images/gallery/DUC_7906_big.jpeg';
import Gallery7906Thumb from '@assets/images/gallery/DUC_7906_thumb.jpeg';
import Gallery7991 from '@assets/images/gallery/DUC_7991_big.jpeg';
import Gallery7991Thumb from '@assets/images/gallery/DUC_7991_thumb.jpeg';
import Gallery7997 from '@assets/images/gallery/DUC_7997_big.jpeg';
import Gallery7997Thumb from '@assets/images/gallery/DUC_7997_thumb.jpeg';
import Gallery8060 from '@assets/images/gallery/DUC_8060_big.jpeg';
import Gallery8060Thumb from '@assets/images/gallery/DUC_8060_thumb.jpeg';
import Gallery8074 from '@assets/images/gallery/DUC_8074_big.jpeg';
import Gallery8074Thumb from '@assets/images/gallery/DUC_8074_thumb.jpeg';
import Gallery8133 from '@assets/images/gallery/DUC_8133_big.jpeg';
import Gallery8133Thumb from '@assets/images/gallery/DUC_8133_thumb.jpeg';
import Gallery8227 from '@assets/images/gallery/DUC_8227_big.jpeg';
import Gallery8227Thumb from '@assets/images/gallery/DUC_8227_thumb.jpeg';
import Gallery8247 from '@assets/images/gallery/DUC_8247_big.jpeg';
import Gallery8247Thumb from '@assets/images/gallery/DUC_8247_thumb.jpeg';
import Gallery8248 from '@assets/images/gallery/DUC_8248_big.jpeg';
import Gallery8248Thumb from '@assets/images/gallery/DUC_8248_thumb.jpeg';
import Gallery8284 from '@assets/images/gallery/DUC_8284_big.jpeg';
import Gallery8284Thumb from '@assets/images/gallery/DUC_8284_thumb.jpeg';
import Gallery8323 from '@assets/images/gallery/DUC_8323_big.jpeg';
import Gallery8323Thumb from '@assets/images/gallery/DUC_8323_thumb.jpeg';
import Gallery8338 from '@assets/images/gallery/DUC_8338_big.jpeg';
import Gallery8338Thumb from '@assets/images/gallery/DUC_8338_thumb.jpeg';
import Gallery8353 from '@assets/images/gallery/DUC_8353_big.jpeg';
import Gallery8353Thumb from '@assets/images/gallery/DUC_8353_thumb.jpeg';
import Gallery8379 from '@assets/images/gallery/DUC_8379_big.jpeg';
import Gallery8379Thumb from '@assets/images/gallery/DUC_8379_thumb.jpeg';
import Gallery8406 from '@assets/images/gallery/DUC_8406_big.jpeg';
import Gallery8406Thumb from '@assets/images/gallery/DUC_8406_thumb.jpeg';
import Gallery8433 from '@assets/images/gallery/DUC_8433_big.jpeg';
import Gallery8433Thumb from '@assets/images/gallery/DUC_8433_thumb.jpeg';
import Gallery8447 from '@assets/images/gallery/DUC_8447_big.jpeg';
import Gallery8447Thumb from '@assets/images/gallery/DUC_8447_thumb.jpeg';
import Gallery8452 from '@assets/images/gallery/DUC_8452_big.jpeg';
import Gallery8452Thumb from '@assets/images/gallery/DUC_8452_thumb.jpeg';
import Gallery8462 from '@assets/images/gallery/DUC_8462_big.jpeg';
import Gallery8462Thumb from '@assets/images/gallery/DUC_8462_thumb.jpeg';
import Gallery8548 from '@assets/images/gallery/DUC_8548_big.jpeg';
import Gallery8548Thumb from '@assets/images/gallery/DUC_8548_thumb.jpeg';
import Gallery8554 from '@assets/images/gallery/DUC_8554_big.jpeg';
import Gallery8554Thumb from '@assets/images/gallery/DUC_8554_thumb.jpeg';
import Gallery8562 from '@assets/images/gallery/DUC_8562_big.jpeg';
import Gallery8562Thumb from '@assets/images/gallery/DUC_8562_thumb.jpeg';
import Gallery8519 from '@assets/images/gallery/DUC_8519_big.jpeg';
import Gallery8519Thumb from '@assets/images/gallery/DUC_8519_thumb.jpeg';
import DUC_8491_big from '@assets/images/gallery/DUC_8491_big.jpeg';
import DUC_8491_thumb from '@assets/images/gallery/DUC_8491_thumb.jpeg';
import DUC_8503_big from '@assets/images/gallery/DUC_8503_big.jpeg';
import DUC_8503_thumb from '@assets/images/gallery/DUC_8503_thumb.jpeg';

export const photos = [
  {
    original: Gallery8554,
    thumbnail: Gallery8554Thumb,
  },
  {
    original: Gallery7605,
    thumbnail: Gallery7605Thumb,
  },
  {
    original: Gallery7752,
    thumbnail: Gallery7752Thumb,
  },
  {
    original: Gallery7849,
    thumbnail: Gallery7849Thumb,
  },
  {
    original: Gallery7906,
    thumbnail: Gallery7906Thumb,
  },
  {
    original: Gallery7991,
    thumbnail: Gallery7991Thumb,
  },
  {
    original: Gallery7997,
    thumbnail: Gallery7997Thumb,
  },
  {
    original: Gallery8060,
    thumbnail: Gallery8060Thumb,
  },
  {
    original: Gallery8074,
    thumbnail: Gallery8074Thumb,
  },
  {
    original: Gallery8133,
    thumbnail: Gallery8133Thumb,
  },
  {
    original: Gallery8227,
    thumbnail: Gallery8227Thumb,
  },
  {
    original: Gallery8247,
    thumbnail: Gallery8247Thumb,
  },
  {
    original: Gallery8248,
    thumbnail: Gallery8248Thumb,
  },
  {
    original: Gallery8284,
    thumbnail: Gallery8284Thumb,
  },
  {
    original: Gallery8353,
    thumbnail: Gallery8353Thumb,
  },
  {
    original: Gallery8379,
    thumbnail: Gallery8379Thumb,
  },
  {
    original: Gallery8406,
    thumbnail: Gallery8406Thumb,
  },
  {
    original: Gallery8433,
    thumbnail: Gallery8433Thumb,
  },
  {
    original: Gallery8447,
    thumbnail: Gallery8447Thumb,
  },
  {
    original: Gallery8452,
    thumbnail: Gallery8452Thumb,
  },
  {
    original: Gallery8462,
    thumbnail: Gallery8462Thumb,
  },
  {
    original: Gallery8548,
    thumbnail: Gallery8548Thumb,
  },
  {
    original: DUC_8503_big,
    thumbnail: DUC_8503_thumb,
  },
  {
    original: Gallery8562,
    thumbnail: Gallery8562Thumb,
  },
  {
    original: Gallery8519,
    thumbnail: Gallery8519Thumb,
  },
  {
    original: DUC_8491_big,
    thumbnail: DUC_8491_thumb,
  },
  {
    original: Gallery8323,
    thumbnail: Gallery8323Thumb,
  },
  {
    original: Gallery8338,
    thumbnail: Gallery8338Thumb,
  },
];
